import React from 'react';
import { TouchableOpacity, Image, StyleSheet, Text, View } from 'react-native';


/* Routing stuffs */
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

import { styles } from '../App';

export class PageHeader extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <h1 style={{
        fontFamily: 'sans-serif',
        fontSize: 32,
      }}>{this.props.title}</h1>
    );
  }
}
