import React, { useState } from 'react';
import { TextInput, Platform, TouchableOpacity, Image, StyleSheet, Text, View } from 'react-native';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
} from "react-router-dom";

import { styles } from '../App';
import { PageHeader } from '../components/PageHeader';

import logo from '../assets/speechflix-logo-white-hd.png';

/* Firebase stuffs */
import * as firebase from 'firebase';

function debug(any) {
  if(typeof(any) === 'string') {
    //console.log(any);
    alert(any);
  } else {
    (any)();
  }
}


export class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { email: '', password: '', };
  }

  changeHistory() {
    let history = useHistory();
    history.push("/home");
  }

  render() {
    return (
      <View style={styles.container}>
        <Image source={logo} style={{ width: 375, height: 103 }} />

        <PageHeader title="Login" />
        <TextInput style={styles.textInput}
          placeholder="Email"
          onChangeText={email => this.setState({ email })}
          value={this.state.email}
        />

        <TextInput style={styles.textInput}
          secureTextEntry={true}
          placeholder="******"
          onChangeText={password => this.setState({ password })}
          value={this.state.password}
        />
        <LoginButton state={this.state} />
        <Text style={{ textAlign: 'right' }}>
          <Link to="/register" style={{ textDecoration: 'none' }}>Register</Link>
        </Text>

      </View>
    );
  }
}


const LoginButton = ({state}) => {
  let history = useHistory();

  function handleClick() {
    login(state.email, state.password, () => setTimeout(() => history.push("/home"), 500));
  }

  return (
    <TouchableOpacity onClick={handleClick} style={styles.button}>
      <Text style={styles.buttonText}>Login</Text>
    </TouchableOpacity>
  );
};

class ILoginButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.props.state;

    this.state = { ...this.state, hClick: () => {
        let history = useHistory();
        function changeHistory() {
          history.push({
            pathname: '/home'
          });
        }

        login(this.state.email, this.state.password, changeHistory);
      },
    };
  }


  render() {
    return (
      <TouchableOpacity onClick={this.state.hClick} style={styles.button}>
        <Text style={styles.buttonText}>Login</Text>
      </TouchableOpacity>
    );
  }


}

function login(email, password, changeHistory) {
  firebase.auth().signInWithEmailAndPassword(email, password).then(function() {
    // Redirect is handled with link now.
    // Do nothing
    if(changeHistory !== null) setTimeout(_ => changeHistory(), 500);

  }).catch(function(error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // ...
    alert(`Failed to sign in, code: ${errorCode}, message: ${errorMessage}`);

  });
}

const loginPageStyles = StyleSheet.create({
  loginButton: {
    backgroundColor: "rgb(0, 117, 178)",
    padding: 0,
    paddingLeft: 50,
    paddingRight: 50,
    borderRadius: 5,
    marginTop: 10,
    textDecorationLine: 'none',
  }
});
