import React from 'react';
import { TouchableOpacity, Image, StyleSheet, Text, View } from 'react-native';


/* Global logos etc */
import logo from './assets/speechflix-logo-white-hd.png';

/* Routing stuffs */
import {
  HashRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";


/* Import External Pages */
import { LoginPage } from './pages/LoginPage';
import { RegisterPage } from './pages/RegisterPage';
import { Home } from './pages/Home';
import { OrderPage } from './pages/OrderPage';

import { PageHeader } from './components/PageHeader';


/* Firebase stuffs */
import * as firebase from 'firebase';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyD1Y2xbNLz6Z9FD4tv4UytcJwPnID-_VLU",
  authDomain: "speechflix-5c368.firebaseapp.com",
  databaseURL: "https://speechflix-5c368.firebaseio.com",
  projectId: "speechflix-5c368",
  storageBucket: "speechflix-5c368.appspot.com",
  messagingSenderId: "43973334719",
  appId: "1:43973334719:web:33e0f392a30b6ad0c5980a",
  measurementId: "G-JLRN6TZE8L"
};

//firebase.initializeApp(firebaseConfig);


export default function App() {
  return (
    <Router>
      <Switch>
        <Route path="/landing">
          <LandingPage />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/register">
          <RegisterPage />
        </Route>
        <Route path="/order">
          <OrderPage />
        </Route>
        <Route path="/forgot">
          <ForgotPage />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/home">
          <Home />
        </Route>
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </Router>
  );
}

function NoMatch() {
  return (
    <View style={styles.container}>
      <PageHeader title="Page not found!" />

      <Link style={{textDecoration: 'none', textAlign: 'center', color: '#000'}} to="/">
        <TouchableOpacity style={styles.button}>
          <Text style={styles.buttonText}>Go Back</Text>
        </TouchableOpacity>
      </Link>
    </View>
  );
}

function ForgotPage() {
  return (
    <View style={styles.container}>This page is under construction.</View>
  );
}

function LandingPage() {
  return (
    <View style={styles.container}>

      <View style={styles.logo}>
        <Image source={logo} style={{ width: 375, height: 103 }} />
        <Text style={styles.centeredText}>Interactive Therapy Videos</Text>
      </View>


      <View style={styles.options}>

        <Link to={'/login'} style={{textDecoration: 'none'}}>
          <TouchableOpacity style={styles.button}>
            <Text style={styles.buttonText}>Login</Text>
          </TouchableOpacity>
        </Link>

        <Link to={'/register'} style={{ textDecoration: 'none' }}>
          <TouchableOpacity style={styles.button}>
            <Text style={styles.buttonText}>Register</Text>
          </TouchableOpacity>
        </Link>

        <Link style={{textDecoration: 'none', color: '#000', marginTop: 3, textAlign: 'center', }} to="/forgot">
          <Text style={{textAlign: 'center', textDecorationLine: 'underline', marginTop: 3, }}>Forgot Password?</Text>
        </Link>

      </View>
    </View>
  );
}

// function LoginPage() {
//   return <h1>Login</h1>;
// }

// function RegisterPage() {
//   return <h1>Register</h1>;
// }

/*
  TODO: Change this so that the Home page will check for login, and if it isn't logged in, will allow the login.
*/
// function Home() {
//   return <Redirect to="/landing" />;
// }

export const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 25,
  },
  vContainer: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    marginBottom: 25,
  },
  justifyBottom: {
    justifyContent: 'bottom',
  },
  logo: {
    flex: 0,
    marginTop: 0,
  },
  pageHeader: {
    fontSize: 32,
  },
  button: {
    backgroundColor: "rgb(0, 117, 178)",
    padding: 20,
    paddingLeft: 100,
    paddingRight: 100,
    borderRadius: 5,
    marginTop: 10,
    textDecorationLine: 'none',
  },
  redButton: {
    backgroundColor: "rgb(204,51,0)",
    padding: 20,
    paddingLeft: 100,
    paddingRight: 100,
    borderRadius: 5,
    marginTop: 10,
    textDecorationLine: 'none',
  },
  buttonText: {
    fontSize: 20,
    color: '#fff',
    textAlign: 'center',
    textDecorationLine: 'none',
  },
  smallButton: {
    backgroundColor: "rgb(0, 117, 178)",
    margin: 10,
    padding: 20,
    paddingLeft: 50,
    paddingRight: 50,
    borderRadius: 5,
    marginTop: 10,
    textDecorationLine: 'none',
  },
  options: {
    flex: 0,
    marginTop: 150,
    marginBottom: 15,
  },
  centeredText: {
    textAlign: 'center',
  },
  textInput: {
    borderColor: "rgb(0, 117, 178)",
    borderWidth: 0.5,
    padding: 20,
    paddingLeft: 70,
    paddingRight: 70,
    borderRadius: 5,
    marginTop: 10,
  },
  mTextInput: {
    borderColor: "rgb(0, 117, 178)",
    borderWidth: 0.5,

    textAlign: "left",



    height: 150,

    padding: 20,
    paddingLeft: 70,
    paddingRight: 70,


    borderRadius: 5,
    marginTop: 10,
  },
  thumbnail: {
    width: 300,
    height: 300,
    resizeMode: "contain"
  },
  // yuh
  header: {
    backgroundColor: '#F5FCFF',
    padding: 10,
  },
  headerText: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '500',
  },
  content: {
    padding: 20,
    backgroundColor: '#fff',
  },
  active: {
    backgroundColor: 'rgba(255,255,255,1)',
  },
  inactive: {
    backgroundColor: 'rgba(245,252,255,1)',
  },
  selectors: {
    marginBottom: 10,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  selector: {
    backgroundColor: '#F5FCFF',
    padding: 10,
  },
  activeSelector: {
    fontWeight: 'bold',
  },
  selectTitle: {
    fontSize: 14,
    fontWeight: '500',
    padding: 10,
    textAlign: 'center',
  },
  goal: {
    color: '#000',
    margin: 20,
  },
  goalCategory: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#000',
  },
  selectedGoal: {
    color: '#A1f',
    margin: 20,
  },
  instructions: {
    fontWeight: 'bold'
  },
  warning: {
    fontWeight: 'bold',
    color: "rgb(204,51,0)"
  }
});
