/**
 * Home : React.Component
 * This page is the dashboard of a user's functions.
 * They can view their previous orders here, as well as place a new one, or sign out.
 *
 * TODO: Add order viewing.
 */

import React from 'react';
import { TextInput, Platform, TouchableOpacity, Image, StyleSheet, Text, View } from 'react-native';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";

import { styles } from '../App';
import { PageHeader } from '../components/PageHeader';
import OrderStatus from '../components/OrderStatus';

import logo from '../assets/speechflix-logo-white-hd.png';

/* Firebase stuffs */
import * as firebase from 'firebase';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyD1Y2xbNLz6Z9FD4tv4UytcJwPnID-_VLU",
  authDomain: "speechflix-5c368.firebaseapp.com",
  databaseURL: "https://speechflix-5c368.firebaseio.com",
  projectId: "speechflix-5c368",
  storageBucket: "speechflix-5c368.appspot.com",
  messagingSenderId: "43973334719",
  appId: "1:43973334719:web:33e0f392a30b6ad0c5980a",
  measurementId: "G-JLRN6TZE8L"
};

/**
 * Apparently we want to initialize this in the '/' root.
 */
firebase.initializeApp(firebaseConfig);

// We assume authentication by default and allow the real loading state through.
let isAuthenticated = true;


export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      isAuthenticated: true,
    };
  }

  componentDidMount() {
    this._asyncRequest = firebase.auth().onAuthStateChanged(user => {
      this._asyncRequest = null;

      if(user === null) {
        isAuthenticated = false;
      } else {
        isAuthenticated = true;
      }

      this.setState({ user, isAuthenticated });
    });
  }

  componentWillReceiveProps(newProps) {
    this._asyncRequest = firebase.auth().onAuthStateChanged(user => {
      this._asyncRequest = null;

      if(user === null) {
        isAuthenticated = false;
      } else {
        isAuthenticated = true;
      }

      this.setState({ user, isAuthenticated });
    });
  }

  componentWillUnmount() {
    if (this._asyncRequest) {
      this._asyncRequest.cancel();
    }
  }

  render() {

    /*
    <View style={styles.container}>
      <Text>Sign in to access this page</Text>
      <Link style={{ textDecoration: 'none', }} to="/login">
        <TouchableOpacity style={styles.button}>
          <Text style={styles.buttonText}>Login</Text>
        </TouchableOpacity>
      </Link>
    </View>
    */
    if(!this.state.isAuthenticated) {
      return (
        <Redirect
          to={{
            pathname: "/landing",
          }}
        />
      )
    }

    if(this.state.user === null) {
      // Loading
      return (
        <View style={styles.container}>
          <Text>Loading...</Text>
        </View>
      );
    }

    if(!this.state.user.emailVerified) {
      return (
        <VerifyEmail />
      );
    }



    return (
      <View style={styles.container}>
        <Image source={logo} style={{ width: 375, height: 103 }} />
        <PageHeader title="Home" />

        <Text style={{ marginBottom: 20, }}>Logged in.</Text>




        <Link style={{ textDecoration: 'none', }} to="/order">
          <TouchableOpacity style={styles.button}>
            <Text style={styles.buttonText}>Order</Text>
          </TouchableOpacity>
        </Link>
        <LogoutButton />

      </View>
    );
  }
}

const VerifyEmail = (props) => {

  const [status, setStatus] = React.useState("");


  const sendVerificationEmail = () => {
    var actionCodeSettings = {
      url: "https://panel.speechflix.com/home"
    };

    firebase.auth().currentUser.sendEmailVerification(actionCodeSettings)
    .then(function() {
      // Verification email sent.
      setStatus("Verification Email Sent!");
    })
    .catch(function(error) {
      // Error occurred. Inspect error.code.
      //alert(error.message);
    });
  };


  return (
    <View style={styles.container}>
      <Text style={{marginBottom: 20}}>{status}</Text>
      <Text style={styles.warning}>Please verify your email!</Text>
      <View style={{ flexDirection: 'row' }}>
        <LogoutButton small={true} />
        <TouchableOpacity style={styles.smallButton} onClick={sendVerificationEmail()}>
          <Text style={styles.buttonText}>Verify Email</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};


function LogoutButton(props) {
  let history = useHistory();

  function handleClick() {
    signout(() => history.push("/landing"));
  }

  if(props.small) {
    return (
      <TouchableOpacity onClick={handleClick} style={styles.smallButton}>
        <Text style={styles.buttonText}>Sign Out</Text>
      </TouchableOpacity>
    );
  }

  return (
    <TouchableOpacity onClick={handleClick} style={styles.button}>
      <Text style={styles.buttonText}>Sign Out</Text>
    </TouchableOpacity>
  );
}


function signout(changeHistory) {
  firebase.auth().signOut().then(function() {
    changeHistory();

  }).catch(function(error) {
    alert(error);
  });
}
