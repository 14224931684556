import React from 'react';
import { TextInput, Platform, TouchableOpacity, Image, StyleSheet, Text, View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory
} from "react-router-dom";

import { styles } from '../App';
import { PageHeader } from '../components/PageHeader';

import logo from '../assets/speechflix-logo-white-hd.png';

import * as firebase from 'firebase';


export class RegisterPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = { firstName: '', lastName: '', email: '', password: '', };
  }

  render() {
    return (
      <View style={styles.container}>
        <Image source={logo} style={{ width: 375, height: 103 }} />

        <PageHeader title="Register" />

        <TextInput style={styles.textInput}
          placeholder="First Name"
          onChangeText={firstName => this.setState({ firstName })}
          value={this.state.firstName}
        />
        <TextInput style={styles.textInput}
          placeholder="Last Name"
          onChangeText={lastName => this.setState({ lastName })}
          value={this.state.lastName}
        />








        <TextInput style={styles.textInput}
          placeholder="Email"
          onChangeText={email => this.setState({ email })}
          value={this.state.email}
        />

        <TextInput style={styles.textInput}
          secureTextEntry={true}
          placeholder={"Password"}
          onChangeText={password => this.setState({ password })}
          value={this.state.password}
        />
        <RegisterButton firstName={this.state.firstName} lastName={this.state.lastName} email={this.state.email} password={this.state.password} />
        <HelpButton style={{ marginTop: 40 }} />
      </View>
    );
  }
}

function register(firstName, lastName, email, password, changeHistory) {
  // alert(`${firstName}, ${lastName}, ${email}, ${password}`);

  firebase.auth().createUserWithEmailAndPassword(email, password).then(function(userCredential) {

    // go into firebase and set other stuff.
    userCredential.user.updateProfile({
      displayName: `${firstName} ${lastName}`
    }).then(() => {

      changeHistory();

    }).catch(error => {
      alert(error);
    });



  }).catch(function(error) {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    // ...

    alert(`Error: (${errorCode}) ${errorMessage}`);
  });
}


const RegisterButton = (props) => {

  let history = useHistory();

  const changeHistory = () => {
    history.push("/home");
  };

  return (
    <TouchableOpacity onClick={() => register(props.firstName, props.lastName, props.email, props.password, changeHistory)} style={styles.button}>
      <Text style={styles.buttonText}>Register</Text>
    </TouchableOpacity>
  );
};


const HelpButton = (props) => {
  const helpAlert = () => {
    alert(`Speechflix cultivates language and concept explosion by providing
children/parents and providers with personalized and interactive
video based solutions implemented during their daily routines.
Speechflix should be considered supplemental to traditional
Speech Therapy with best results when goals are provided from a
current Plan of Care to ensure appropriate skills are being targeted.
Although all content and completed projects will be protected from
unauthorized use, Speechflix is not a medical product and not
billable through medical insurance.`);
  };

  return (
    <TouchableOpacity onClick={helpAlert}>
      <Ionicons name="md-help-circle" size={32} color="black" />
    </TouchableOpacity>
  );
}


const loginPageStyles = StyleSheet.create({
  loginButton: {
    backgroundColor: "rgb(0, 117, 178)",
    padding: 0,
    paddingLeft: 50,
    paddingRight: 50,
    borderRadius: 5,
    marginTop: 10,
    textDecorationLine: 'none',
  }
});
