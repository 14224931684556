import React, { useState, useEffect } from 'react';
import { ScrollView, TextInput, Platform, TouchableOpacity, Image, StyleSheet, Text, View } from 'react-native';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useRouteMatch,
  useParams,
} from "react-router-dom";

import * as ImagePicker from 'expo-image-picker';

import * as firebase from 'firebase';

import { v4 as uuidv4 } from 'uuid';

let serviceAccount = require('../secrets/speechflix-5c368-b2794b5db933.json');
// const {Firestore} = require('@google-cloud/firestore');


import { styles } from '../App';
import { PageHeader } from '../components/PageHeader';
import { OrderStatus } from '../components/OrderStatus';



import logo from '../assets/speechflix-logo-white-hd.png';

let orderState = { uid: "undefined-user", info: {}, goals: null, id: null, images: [], paymentSuccesful: null };
let g_Selected = new Array();
let totalSelected = 0;

const MINIMUM_IMAGES = 2;
const MAX_IMAGES = 25;


const CONTENT = [
  {
    title: 'Terms and Conditions',
    content:
      'The following terms and conditions, together with any referenced documents (collectively, "Terms of Use") form a legal agreement between you and your employer, employees, agents, contractors and any other entity on whose behalf you accept these terms (collectively, “you” and “your”), and ServiceNow, Inc. (“ServiceNow,” “we,” “us” and “our”).',
  },
  {
    title: 'Privacy Policy',
    content:
      'A Privacy Policy agreement is the agreement where you specify if you collect personal data from your users, what kind of personal data you collect and what you do with that data.',
  },
  {
    title: 'Return Policy',
    content:
      'Our Return & Refund Policy template lets you get started with a Return and Refund Policy agreement. This template is free to download and use.According to TrueShip study, over 60% of customers review a Return/Refund Policy before they make a purchasing decision.',
  },
];


export const OrderPage = (props) => {
  let { path, url } = useRouteMatch();
  let history = useHistory();
  let _asyncRequest = null;


  const [user, setUser] = React.useState(null);



  const gotoLogin = () => {
    history.push("/home");
  };

  useEffect(() => {
    _asyncRequest = firebase.auth().onAuthStateChanged(nUser => {
      _asyncRequest = null;

      if(nUser === null) {
        gotoLogin();
      } else {
        setUser(nUser);
        orderState.uid = nUser.uid;
      }
    });
  });

  return (
    <View style={styles.vContainer}>
      <Switch>
        <Route path={`${path}/1`}>
          <OrderSetup />
        </Route>
        <Route path={`${path}/2`}>
          <GoalSelection />
        </Route>
        <Route path={`${path}/3`}>
          <ImageUpload />
        </Route>
        <Route path={`${path}/4`}>
          <Checkout user={user} />
        </Route>
        <Route exact path={path}>
          <Redirect to={`${path}/1`} />
        </Route>
      </Switch>
    </View>
  );
};


const OrderSetup = props => {

  const [childName, setChildName] = React.useState("");
  const [birthDate, setBirthdate] = React.useState("");
  const [diagnosis, setDiagnosis] = React.useState("");
  const [diagnosisDate, setDiagnosisDate] = React.useState("");
  const [currentTherapist, setCurrentTherapist] = React.useState("");
  // CURRENT WORD USE?????
  const [likes, setLikes] = React.useState("");
  const [family, setFamily] = React.useState("");
  const [places, setPlaces] = React.useState("");

  const [attendsDaycare, setAttendsDaycare] = React.useState(false);
  const [promotion, setPromotion] = React.useState(false);

  useEffect(() => {
    if(orderState.info.childName !== "") {
      // Fill up the state here.
    }
  });


  const cacheInfo = () => {
    orderState.info = { childName, birthDate, diagnosis, diagnosisDate, currentTherapist, likes, family, places, attendsDaycare, promotion };
  };

  return (
      <View style={styles.vContainer}>
        <PageHeader title="Order Setup" />
        <TextInput style={styles.textInput}
          placeholder="Child's Name"
          onChangeText={cName => setChildName(cName)}
          value={childName}
        />

        <TextInput style={styles.textInput}
          placeholder="Child's Birthdate (mm/dd/yyyy)"
          onChangeText={bDate => setBirthdate(bDate)}
          value={birthDate}
        />

        <TextInput style={styles.textInput}
          placeholder="Diagnosis (if any)"
          onChangeText={nDiagnosis => setDiagnosis(nDiagnosis)}
          value={diagnosis}
        />

        <TextInput style={styles.textInput}
          placeholder="Date of Diagnosis (mm/dd/yyyy)"
          onChangeText={nDate => setDiagnosisDate(nDate)}
          value={diagnosisDate}
        />

        <TextInput style={styles.textInput}
          placeholder="Current Therapist or IEP"
          onChangeText={cTherapist => setCurrentTherapist(cTherapist)}
          value={currentTherapist}
        />

        <TextInput style={styles.mTextInput}
          multiline={true}
          placeholder="Likes and Dislikes"
          onChangeText={nLikes => setLikes(nLikes)}
          value={likes}
        />

        <TextInput style={styles.mTextInput}
          multiline={true}
          placeholder="Family Members (name - position)"
          onChangeText={nFamily => setFamily(nFamily)}
          value={family}
        />

        <TextInput style={styles.mTextInput}
          multiline={true}
          placeholder="Favorite Places"
          onChangeText={nPlaces => setPlaces(nPlaces)}
          value={places}
        />

        <Link to="/order/2" style={{ textDecoration: 'none' }}>
          <TouchableOpacity style={styles.button} onClick={cacheInfo}>
            <Text style={styles.buttonText}>Next</Text>
          </TouchableOpacity>
        </Link>



      </View>
  );
};











const hints = {
  "Imitate expressions, silly sounds, environmental sounds, non-speech words": '(Picture of child with his firetruck “woo woo”, picture with baby doll for “waah waah”, picture blowing bubbles for “pop pop”, bus “beep beep”, picture with family dog or cat',
  "Imitate simple words": 'Picture of child with with bear, banana, apple, mom, dad, cup, milk, ball, book, toy bus',
  "Imitate reduplicated syllables": 'Pictures of mama, dada, nana, papa, with Santa (for ho ho), picture of family dog “woof woof”',
  "Say mama/dada meaningfully": 'Pictures or short video clips of mom and dad with child, and also doing actions around the house such as mom cooking, petting dog',
  "Imitate/produce two-word phrases with pivot words (want, bye, eat, etc.)": 'Pictures of child eating different foods “eat apple”, “eat nana”, “eat cookie”, “eat chip”',


  "Name action words": 'Videos (15-20 seconds) of child running, jumping, swimming, dancing, shopping, eating, drinking, sleeping, building, riding bike, playing, feeding pet',
  "Name simple objects": 'Pictures of objects from your house such as child’s bed, picture of your house, child’s cup, child’s toys such as car, truck, book, ball, bike, dog, cat, kitchen table',
  "Use spatial concepts (choose no more than 3 spatial concepts to target)": ' Pictures of the child or favorite object under the table, under chair, beside couch, beside favorite toy, beside mom, beside dog',
  "Name object functions": 'Picture of your TV, chair, sink, hairbrush, oven, phone, spoon, toothbrush, knife, keys, soap, towel, etc.  Can have a picture/video of the child/caregiver using the object',
  "Answer who questions": 'Pictures/short video clips of parents, siblings, caregivers, teachers, speech therapists, OT, PT, EI',
  "Answer simple what questions (items around the house, toys, foods)": ' Pictures of objects from your house such as child’s bed, picture of your house, child’s cup, child’s toys such as car, truck, book, ball, bike, dog, cat, kitchen table',
  "Answer simple what questions (school items)": 'Pictures of child with crayons, markers, scissors, paint, stapler, dot markers',
  "Answer where questions (spatial concepts)": 'Pictures of child beside mom/dad, behind a chair, in front of the sofa, besdie the mailbox, behind a sibling, etc.  It may be good to use the same object person and picture in front, behind, beside.',
  "Answer where questions (places around the house)": 'Pictures of child or parents in certain rooms of house such as kitchen, bedroom, playroom, bathroom, porch, outside, in yard, etc',
  "Answer where questions (places in the community)": 'Pictures of child, siblings, parents in the community such as library, favorite restaurants, grocery store, preschool, YMCA, gym, favorite park or playground',
};


function retrieveGoals() {

  let goalRefs = new Map();

  // Firebase retrieve stuffs
  let goalCategories = firebase.firestore().collection('goal-categories');
  goalCategories.get().then(querySnapshot => {
    querySnapshot.forEach(docSnapshot => {
      goalRefs.set(docSnapshot.id, docSnapshot.ref.collection('goals'));
    });
  });


  for(let collection of goalRefs.entries()) {
    // do stuff here.
  }




}


let heldPhotos = [];
let photos = [];

// We need to fill out our captions otherwise Firebase doesn't like that some are undefined.
let captions = new Array();


function ImageUpload() {
  let [selectedImage, setSelectedImage] = React.useState(null);

  let history = useHistory();


  let openImagePickerAsync = async () => {
    let permissionResult = await ImagePicker.requestCameraRollPermissionsAsync();

    if (permissionResult.granted === false) {
      alert('Permission to access camera roll is required!');
      return;
    }

    let pickerResult = await ImagePicker.launchImageLibraryAsync();

    if (pickerResult.cancelled === true) {
      return;
    }

    setSelectedImage({ localUri: pickerResult.uri });
  };

  const getImage = (item, i) => {
    return (
      <ImagePadlet item={item} index={i} />
    );
  }

  const getHint = () => {
    if(heldPhotos.length < 25/2) {
      return hints[g_Selected[0]];
    } else {
      return hints[g_Selected[1]];
    }
  };

  const getGoal = () => {
    if(heldPhotos.length < 25/2) {
      return g_Selected[0];
    } else {
      return g_Selected[1];
    }
  };


  function checkAndContinue(rerouteFunction) {
    orderState.images = photos;

    if(heldPhotos.length >= MINIMUM_IMAGES) {
      history.push("/order/4");
    } else {
      alert("You have not selected enough images to continue!");
    }

  }

  if (selectedImage !== null) {
    photos.push({ localUri: selectedImage.localUri });
    heldPhotos.push(getImage(selectedImage, photos.length - 1));

    return (
      <View style={styles.vContainer}>
        <PageHeader title="Upload Images" />
        <Text style={styles.warning}>Videos are not supported yet!</Text>
        <Text style={styles.instructions}>
          Please select at least 12 images per goal; once a total of 25 is added, press next
        </Text>
        <Text style={{ fontWeight: "bold" }}>Current Goal: </Text><Text style={{textAlign: "center"}}>{getGoal()}</Text>
        <Text style={{ fontWeight: "bold" }}>Hint: </Text><Text style={{textAlign: "center"}}>{getHint()}</Text>


        <Text style={{fontSize: 24}}>Images</Text>
        <ScrollView style={{ marginBottom: 25, }}>
          {heldPhotos}
        </ScrollView>

        <TouchableOpacity onPress={openImagePickerAsync} style={styles.button}>
          <Text style={styles.buttonText}>Add Image</Text>
        </TouchableOpacity>

        <Text>Uploaded: {heldPhotos.length} / 25 </Text>

        <TouchableOpacity onClick={checkAndContinue} style={styles.button}>
          <Text style={styles.buttonText}>Next</Text>
        </TouchableOpacity>
      </View>
    );
  } else if(heldPhotos !== null && heldPhotos.length > 0) {
    return (
      <View style={styles.vContainer}>
        <PageHeader title="Upload Images" />
        <Text style={styles.warning}>Videos are not supported yet!</Text>
        <Text style={styles.instructions}>
          Please select at least 12 images per selected goal
        </Text>
        <Text style={{ fontWeight: "bold" }}>Current Goal: </Text><Text style={{textAlign: "center"}}>{getGoal()}</Text>
        <Text style={{ fontWeight: "bold" }}>Hint: </Text><Text style={{textAlign: "center"}}>{getHint()}</Text>


        <Text style={{fontSize: 24}}>Images</Text>
        <ScrollView style={{ marginBottom: 25, }}>
          {heldPhotos}
        </ScrollView>

        <TouchableOpacity onPress={openImagePickerAsync} style={styles.button}>
          <Text style={styles.buttonText}>Add Image</Text>
        </TouchableOpacity>

        <Text>Uploaded: {heldPhotos.length} / 25 </Text>

        <TouchableOpacity onClick={checkAndContinue} style={styles.button}>
          <Text style={styles.buttonText}>Next</Text>
        </TouchableOpacity>
      </View>
    );
  }

  return (
    <View style={styles.vContainer}>
      <PageHeader title="Upload Images" />
      <Text style={styles.warning}>Videos are not supported yet!</Text>
      <Text style={styles.instructions}>
        Please select at least 12 images per selected goal
      </Text>
      <View style={{marginTop: 40}}></View>
      <Text style={{ fontWeight: "bold" }}>Current Goal: </Text><Text style={{textAlign: "center"}}>{getGoal()}</Text>
      <Text style={{ fontWeight: "bold" }}>Hint: </Text><Text style={{textAlign: "center"}}>{getHint()}</Text>

      <TouchableOpacity onPress={openImagePickerAsync} style={styles.button}>
        <Text style={styles.buttonText}>Add Image</Text>
      </TouchableOpacity>
      <Text>Uploaded: 0 / 25</Text>

      <TouchableOpacity onClick={checkAndContinue} style={styles.button}>
        <Text style={styles.buttonText}>Next</Text>
      </TouchableOpacity>
    </View>
  );
}


const ImagePadlet = (props) => {
  const [caption, setCaption] = React.useState("");
  let item = props.item;
  let i = props.index;

  return (
    <View>
      <Image
        source={{ uri: item.localUri }}
        style={styles.thumbnail}
      />
      <TextInput style={styles.textInput}
        placeholder="Caption"
        onChangeText={caption => captions[i] = caption}
        value={captions[i]}
      />
    </View>
  );
};

/**
 * Select goals from categories (all data pulled asynchronously from database).
 */
function GoalSelection() {

  const [data, setData] = React.useState("");

  useEffect(() => {
    // On mount, reset selected to 0.
    //totalSelected = 0;
    //g_Selected = new Array();

    //retrieveGoals();
  });

  return (
    <View style={styles.vContainer}>
      <PageHeader title="Goal Selection" />
      <Text style={styles.instructions}>Select two main goals for this video</Text>


      <GoalList>
        <GoalCategory>Building to Verbal Imitation</GoalCategory>
        <Goal g_Selected={g_Selected}>Imitate expressions, silly sounds, environmental sounds, non-speech words</Goal>
        <Goal g_Selected={g_Selected}>Imitate simple words</Goal>
        <Goal g_Selected={g_Selected}>Imitate reduplicated syllables</Goal>
        <Goal g_Selected={g_Selected}>Say mama/dada meaningfully</Goal>
        <Goal g_Selected={g_Selected}>Imitate/produce two-word phrases with pivot words (want, bye, eat, etc.)</Goal>

        <GoalCategory>Expressive Language</GoalCategory>
        <Goal g_Selected={g_Selected}>Name action words</Goal>
        <Goal g_Selected={g_Selected}>Name simple objects</Goal>
        <Goal g_Selected={g_Selected}>Use spatial concepts (choose no more than 3 spatial concepts to target)</Goal>
        <Goal g_Selected={g_Selected}>Name object functions</Goal>
        <Goal g_Selected={g_Selected}>Answer who questions</Goal>
        <Goal g_Selected={g_Selected}>Answer simple what questions (items around the house, toys, foods)</Goal>
        <Goal g_Selected={g_Selected}>Answer simple what questions (school items)</Goal>
        <Goal g_Selected={g_Selected}>Answer where questions (spatial concepts)</Goal>
        <Goal g_Selected={g_Selected}>Answer where questions (places around the house)</Goal>
      </GoalList>
      <Link style={{ textDecoration: 'none', }} to="/order/3">
        <TouchableOpacity style={styles.button} onClick={() => { orderState.goals = g_Selected; }}>
          <Text style={styles.buttonText}>Next</Text>
        </TouchableOpacity>
      </Link>
    </View>
  );
}


function Goal(props) {

  const [selected, setSelected] = React.useState(props.selected != undefined ? props.selected : false);

  useEffect(() => {
     setSelected(g_Selected.includes(props.children));
  });

  if(selected) {
    return (
      <TouchableOpacity style={styles.selectedGoal} onClick={() => {

        setSelected(false);
        totalSelected--;

        g_Selected = g_Selected.filter(e => {
          return e !== props.children.toString()
        });




      }}>
        {props.children}
      </TouchableOpacity>
    );
  } else {
    return (
      <TouchableOpacity style={styles.goal} onClick={() => {
        if(totalSelected < 2) {
          setSelected(true);
          totalSelected++;

          g_Selected.push(props.children.toString());
        }
      }}>
        {props.children}
      </TouchableOpacity>
    );
  }
}

function GoalCategory(props) {
  return (
    <Text style={styles.goalCategory}>{props.children}</Text>
  );
}

function GoalList(props) {
  return (
    <View style={{ textAlign: 'center' }}>
      {props.children}
    </View>
  );
}

const Loading = (props) => {
  if(props.loading) {
    return (<Text style={{fontSize: 24}}>LOADING...</Text>);
  } else return null;
};



function Checkout(props) {

  let [notes, setNotes] = React.useState("");
  let [loading, setLoading] = React.useState(false);
  let [progress, setProgress] = React.useState(0);

  let history = useHistory();




  async function beginCheckout(iNotes) {

    // possibly display loading stuffs here
    // TODO: DO this

    let uploadImage = async(uri, count) => {
      const response = await fetch(uri);
      const blob = await response.blob();

      var ref = firebase.storage().ref().child(`/orders/${id}/00${count}`);
      return ref.put(blob);
    }

    // first we generate the order's ID
    let id = uuidv4();

    orderState.id = id;
    let orderId = id;


    // TODO: Leaving these redundant checks here because they're still useful.
    if(orderState.uid === undefined) {
      orderState.uid = "undefined-user";
    }

    if(orderId === undefined) {
      orderId = "order-id";
    }

    if(orderState.info === undefined) {
      orderState.info = "order-info";
    }

    if(iNotes === undefined) {
      iNotes = "";
    }

    if(captions === undefined) {
      captions = [];
    }

    for(var i = 0; i < MAX_IMAGES; i++) {
      if(captions[i] === undefined) {
        captions[i] = "";
      }
    }



    let addDoc = await firebase.firestore().collection('orders').doc(orderId).set({
      client: orderState.uid !== undefined ? orderState.uid : "undefined-user",
      id: orderId !== undefined ? orderId : "order-id",
      info: orderState.info !== undefined ? orderState.info : "order-info",
      goals: g_Selected === undefined ? "" : g_Selected,
      notes: iNotes === undefined ? "" : iNotes,
      captions: captions === undefined ? "" : captions,
      created: firebase.firestore.Timestamp.fromDate(new Date())
    }).then(async function() {



      setLoading(true);
      for(let i = 0; i < photos.length; i++) {
        let image = photos[i];

        await uploadImage(image.localUri, i+1).then(function() {
          console.log(`Uploaded ${image.localUri}`);
          setProgress(progress + 1);

          if(i >= photos.length - 1) {



            console.log("Sending user to the homepage, finished uploading");
            setLoading(false);
            history.push("/home");
          }
        });
      }

      setLoading(false);



    }).then(function() {
      let orderState = { uid: "undefined-user", info: {}, goals: null, id: null, images: [], paymentSuccesful: null };
      heldPhotos = [];
      photos = [];

      g_Selected = [];
      totalSelected = 0;
    });






  }




  return (
    <View style={{ flex: 1 }}>
      <View style={styles.vContainer}>

        <PageHeader title="Checkout" />

        <Text>Progress: {progress}/{photos.length}</Text>
        <Loading loading={loading} />


        <Text style={{ fontWeight: 'bold' }}>Goals</Text>
        <Text>{g_Selected[0]}</Text>
        <Text>{g_Selected[1]}</Text>
        <View style={{ paddingTop: 30 }}></View>


        <Text>Please let us know anything else relevant to your order!</Text>
        <TextInput style={styles.mTextInput}
          multiline={true}
          placeholder="Notes"
          onChangeText={changed => setNotes(changed)}
          value={notes}
        />
      </View>

      <View style={styles.vContainer}>
        <View style={{ flexDirection: 'column-reverse' }}>
          <TouchableOpacity style={styles.button} onClick={() => { beginCheckout(notes); }}>
            <Text style={styles.buttonText}>Order</Text>
          </TouchableOpacity>
        </View>
        <Link to="/home" style={{ textDecoration: 'none' }}>
          <TouchableOpacity style={styles.redButton} onClick={() => { orderState = { info: {}, goals: null, id: null, images: [], paymentSuccesful: null } }}>
            <Text style={styles.buttonText}>Cancel</Text>
          </TouchableOpacity>
        </Link>
      </View>


    </View>

  );
}
