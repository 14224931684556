import React from 'react';
import { TextInput, Platform, TouchableOpacity, Image, StyleSheet, Text, View } from 'react-native';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";

import { styles } from '../App';
import { PageHeader } from '../components/PageHeader';

import logo from '../assets/speechflix-logo-white-hd.png';


/**
 * (1)------(2)------(3)
 * Status Bar
 */
export class OrderStatus extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <View></View>
    );
  }
}
